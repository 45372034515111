<template>
    <div class="page-module-builder">
        <page-module
            v-for="pageModule in validComponents"
            :module="pageModule"
            :key="`${ pageModule.__typename }-${ pageModule.id }`"
        />
    </div>
</template>

<script>
    import PageModule from '../PageModule/PageModule';

    export default {
        name: 'page-module-builder',

        components: { PageModule },

        props: {
            components: {
                type: Array,
                default: () => ([]),
            },
        },

        computed: {
            /**
             * Return only components that return a typename.
             *
             * This means that we will only return components that
             * we have defined specific queries for.
             *
             * @returns {Array}
             */
            validComponents() {
                return this.components
                    .filter(({ __typename }) => __typename);
            },
        },
    };
</script>

<style lang="scss" scoped>
    //.page-module {
    //    overflow-x: hidden;
    //}
</style>
