<template>
    <div class="dynamic-page">
        <page-module-builder
            :components="page.attributes.components"
        />
    </div>
</template>

<script>
    import PageModuleBuilder from '../../page-modules/PageModuleBuilder/PageModuleBuilder';
    import generatePageHead from '../../../lib/generate-page-head';
    import { runPageModulesAsyncData } from '../../../lib/page-modules';
    import loading from '../../../lib/loading';

    export default {
        name: 'dynamic-page',

        components: { PageModuleBuilder },

        head() {
            return generatePageHead(this, this.page);
        },

        async asyncData(context) {
            try {
                const { $strapi, route, query, store } = context;

                let { path } = route;

                const locale = store.state.locales.locales.find(({ code }) => code === route.path.split('/')[1] ?? 'en')?.code;

                if (locale) {
                    path = path.replace(`/${ locale }`, '');
                }

                loading().start();

                const page = await $strapi.fetchDynamicPage(path, {
                    previewId: query.preview,
                    locale,
                });

                if (!page) {
                    loading().fail();

                    return context.error({
                        statusCode: 404,
                    });
                }

                const locales = page
                    .attributes
                    .localizations
                    .data
                    .map(({ attributes }) => attributes.locale);

                context.store.commit('locales/setPageLocales', [
                    ...new Set([
                        page.attributes.locale,
                        ...locales,
                    ]),
                ]);

                context.store.commit('locales/setLocale', page.attributes.locale || 'en');

                (page.attributes.components || []).forEach((component) => {
                    component.__page = {
                        id: page.id,
                        published_at: page.attributes?.publishedAt,
                    };
                });

                await runPageModulesAsyncData(page, context);

                loading().finish();

                return {
                    page,
                };
            } catch (e) {
                loading().finish();

                //eslint-disable-next-line no-console
                console.log(e);

                context.error({
                    statusCode: 500,
                });
            }
        },
    };
</script>
