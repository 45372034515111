/**
 * Format the image/video source so that images/videos are prefixed with
 * a slash are prepended with the API url.
 *
 * @param {string|undefined|null} src
 *
 * @returns {string}
 */
function formatSrc(src) {
    if (!src) {
        return src;
    }

    if (src.startsWith('/')) {
        return [
            this.$config.API_URL,
            src,
        ].join('');
    }

    return src;
}

/**
 * Format an image/video object to ensure runtime safety.
 *
 * @param {object} mediaObj
 *
 * @returns {object}
 */
function formatMediaObject(mediaObj) {
    const data = {
        src: formatSrc.call(this, mediaObj?.data?.attributes.url),
        alt: mediaObj?.data?.attributes.alternativeText,
        width: mediaObj?.data?.attributes.width,
        height: mediaObj?.data?.attributes.height,
    };

    if (mediaObj?.data?.attributes?.mime) {
        data.type = mediaObj.data.attributes.mime;
    }

    return data;
}

export default formatMediaObject;
