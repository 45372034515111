// A noop loading interface for when $nuxt is not yet ready
const noopLoading = {
    /* eslint-disable no-empty-function */
    finish: () => {},
    start: () => {},
    fail: () => {},
    set: () => {},
    /* eslint-enable no-empty-function */
};

/**
 * Return a function to return either the $nuxt loading
 * controller if in the browser, or a noop if we're on the server.
 *
 * @returns {object}
 */
const loading = () => {
    const $nuxt = typeof window !== 'undefined' && window.$nuxt;

    return ($nuxt && $nuxt.$loading && $nuxt.$loading.set)
        ? $nuxt.$loading
        : noopLoading;
};

export default loading;
