import formatMediaObject from '../utils/formatters/format-media-object';

/**
 * Return the seo object.
 *
 * @param {object} page
 *
 * @returns {object}
 */
const seo = (page) => page.attributes?.seo || {};

/**
 * Generate the page title.
 *
 * @param {object} page
 *
 * @returns {string}
 */
const generateTitle = (page) =>
    seo(page)?.metaTitle;

/**
 * Generate a single meta item.
 *
 * @param {string} name
 * @param {string} content
 * @returns {{hid: string, name: string, content: string}}
 */
const generateMetaItem = (name, content) => ({
    hid: name,
    name,
    content,
});

/**
 * Generate the meta description tag.
 *
 * @param {object} page
 *
 * @returns {{hid: string, name: string, content: string}}
 */
const generateDescription = (page) =>
    generateMetaItem('description', seo(page)?.metaDescription);

/**
 * Generate the meta robots tag.
 *
 * @param {object} page
 *
 * @returns {{hid: string, name: string, content: string}}
 */
const generateRobots = (page) =>
    generateMetaItem('robots', seo(page)?.metaRobots);

/**
 * Generate the meta keywords tag.
 *
 * @param {object} page
 *
 * @returns {{hid: string, name: string, content: string}}
 */
const generateKeywords = (page) =>
    generateMetaItem('keywords', seo(page)?.keywords);

/**
 * Generate the social share meta tag.
 *
 * @param {object} context
 * @param {object} page
 *
 * @returns {{hid: string, name: string, content: string}[]}
 */
const generateSocialMeta = (context, page) => {
    const fallbackImage = formatMediaObject.call(context, seo(page)?.metaImage).src;

    return seo(page)?.metaSocial
        ?.map(({ socialNetwork, description, title, image }) => {
            const prefix = socialNetwork === 'Twitter'
                ? 'twitter'
                : 'og';

            return [
                generateMetaItem(`${ prefix }:title`, title),
                generateMetaItem(`${ prefix }:description`, description),
                generateMetaItem(
                    `${ prefix }:image`,
                    formatMediaObject.call(context, image).src
                        || fallbackImage,
                ),
            ].filter(({ content }) => content);
        }).flat();
};

/**
 * Generate the meta tags.
 *
 * @param {object} context
 * @param {object} page
 *
 * @returns {{hid: string, name: string, content: string}[]}
 */
const generateMetas = (context, page) => {
    const meta = [];
    const _seo = seo(page);

    if (_seo.metaDescription) {
        meta.push(
            generateDescription(page),
        );
    }

    if (_seo.keywords) {
        meta.push(
            generateKeywords(page),
        );
    }

    if (_seo.metaRobots) {
        meta.push(
            generateRobots(page),
        );
    }

    if (_seo.metaSocial?.length) {
        meta.push(
            ...generateSocialMeta(context, page),
        );
    }

    return meta;
};

/**
 * Generate a link item.
 *
 * @param {string} rel
 * @param {string} href
 * @returns {{hid: string, rel: string, href: string}}
 */
const generateLink = (rel, href) => ({
    hid: rel,
    rel,
    href,
});

/**
 * Generate the canonical link item.
 *
 * @param {object} context
 * @param {object} page
 *
 * @returns {{hid: string, rel: string, href: string}}
 */
const generateCanonical = (context, page) =>
    generateLink(
        'canonical',
        seo(page)?.canonicalURL
            || [context.$config.APP_URL, context.$route.path].join(''),
    );

/**
 * Generate the links.
 *
 * @param {object} context
 * @param {object} page
 *
 * @returns {{hid: string, rel: string, href: string}[]}
 */
const generateLinks = (context, page) => {
    const links = [];

    links.push(
        generateCanonical(context, page),
    );

    return links;
};

/**
 * Generate the structured data script.
 *
 * @param {object} page
 *
 * @returns {{hid: string, json: object, type: string}}
 */
const generateStructuredData = (page) => ({
    hid: 'structuredData',
    type: 'application/ld+json',
    json: seo(page)?.structuredData,
});

/**
 * Generate the head scripts.
 *
 * @param {object} page
 * @returns {{hid: string, json: object, type: string}[]}
 */
const generateScripts = (page) => {
    const scripts = [];

    if (seo(page)?.structuredData) {
        scripts.push(
            generateStructuredData(page),
        );
    }

    return scripts;
};

/**
 * Generate the page meta head object.
 *
 * @param {object} context
 * @param {object} page
 *
 * @returns {{meta: {hid: string, name: string, content: string}[], link: {hid: string, rel: string, href: string}[], title: string, script: {hid: string, json: object, type: string}[]}}
 */
const generatePageHead = (context, page) => {
    const { locale } = page.attributes;

    let lang = locale;

    if (lang !== 'en') {
        lang = locale.split('-')[0];
    }

    const _seo = seo(page);

    const head = {
        title: undefined,
        meta: [],
        script: [],
        link: [],
        htmlAttrs: {
            lang,
        },
    };

    if (_seo.metaTitle) {
        head.title = generateTitle(page);
    }

    head.meta.push(
        ...generateMetas(context, page),
    );

    head.script.push(
        ...generateScripts(page),
    );

    head.link.push(
        ...generateLinks(context, page),
    );

    const locales = [
        {
            uri: page.attributes.uri,
            locale,
        },
        ...page.attributes.localizations.data.map(({ attributes }) => ({
            uri: attributes.uri,
            locale: attributes.locale,
        })),
    ];

    locales.map(({ uri, locale }) => {
        const path = `${ locale }/${ uri }`
            .replace(/^en/, '')
            .replace('//', '/');

        head.link.push({
            hid: `alternate-hreflang-${ locale }`,
            rel: 'alternate',
            hreflang: locale === 'en'
                ? 'en-GB'
                : locale,
            href: `${ context.$config.APP_URL }/${ path }`,
        });
    });

    return head;
};

export default generatePageHead;
