<template>
    <div class="page-module">
        <component
            :is="componentName"
            :attributes="module"
        />
    </div>
</template>

<script>
    import { pageModules, getPageModuleName, isDynamicPageTemplate } from '../../../lib/page-modules';

    export default {
        name: 'page-module',

        components: pageModules,

        props: {
            module: {
                type: Object,
                required: true,
            },
        },

        computed: {
            /**
             * Return the normalised component name based on the graphql __typename field.
             *
             * @returns {string}
             */
            componentName() {
                const moduleName = getPageModuleName(this.module.__typename);

                if (this.$options.components[moduleName]) {
                    return moduleName;
                }

                // If we haven't registered a component for the module provided and it is a template
                // we will render the dynamic page template which just renders dynamic modules
                if (isDynamicPageTemplate(this.module.__typename)) {
                    return 'DynamicPageTemplate';
                }

                return moduleName;
            },
        },
    };
</script>
